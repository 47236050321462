<template>
  <v-container v-if="!loaderVisible" fluid :class="!affiliationMicrositeData.published?'grayscale-disabled-element':''">
    <v-row class="px-3 px-md-1 pt-3 pt-md-10">
      <v-col cols="12" sm="10" offset-sm="1" >
        <v-img v-if="affiliationMicrositeData.banner && affiliationMicrositeData.banner!=''"
          :src="affiliationMicrositeData.banner"
          class="banner-img rounded-xl"
          max-height="500" width="auto"
        ></v-img>
        <!--<v-img v-else max-height="500" width="auto"
          src="@/assets/images/landing/bannerMicrosite1.png"
          class="banner-img"
        ></v-img>-->
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.mdAndUp?'py-5':'px-2'">
      <v-col cols="12" sm="10" offset-sm="1">
        <v-row class="py-5 mx-1" v-bind:style="`background-color: ${primaryColor}; border-radius: 20px`">
          <v-col cols="12" md="8" lg="9" class="px-5 px-md-12">
            <p class="mb-1 font-weight-black display-1" :style="`color:${secondaryColor}`">
              {{affiliationMicrositeData.name}}
            </p>
          </v-col>
          <v-col cols="12" md="4" lg="3" class="d-flex align-center">
            <v-row class="d-flex justify-end">
              <v-col cols="12" class="pa-0 d-flex justify-center">
                <v-btn
                  :color="buttonColor"
                  rounded
                  :disabled="!affiliationMicrositeData.published"
                  :to="{ name: 'affiliationCheckout', params: { affiliationId }}"
                >
                  <span
                    class="font-weight-bold"
                    :style="`color:${affiliationMicrositeData.published?textButtonColor:'#ffffff'}`"
                  >
                    {{affiliationMicrositeData.published?'Convertirme en miembro':'Membresía no disponible'}}
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row :class="$vuetify.breakpoint.mdAndUp?'mt-16':'mt-5 mx-1'">
      <v-col cols="12" sm="10" offset-sm="1" >
        <v-row>
          <v-col cols="12" md="8">
            <v-row>
              <v-col cols="12">
                <p
                  class="headline font-weight-bold"
                  v-bind:style="`color: ${primaryColor}`"
                >
                  Detalles de la membresía
                </p>
                <span v-html="this.affiliationMicrositeData.description" class="affiliation-custom-content"></span>
              </v-col>
              <v-col cols="12" class="mb-10">
                <v-img v-if="affiliationMicrositeData.flyer && affiliationMicrositeData.flyer!=''"
                  :src="affiliationMicrositeData.flyer"
                  class="rounded-xl"
                  height="auto" max-width="800"
                ></v-img>
              </v-col>
              <v-col cols="12" class="d-flex justify-center" v-if="affiliationMicrositeData.youtubeVideo && affiliationMicrositeData.youtubeVideo != ''">
                <h1 class="headline font-weight-bold mb-5">
                  Video publicitario
                </h1>
              </v-col>
              <v-col
                cols="12"
                style="flex-wrap: wrap"
                class="mb-10 mb-md-10 d-flex justify-center"
                data-aos="fade-right"
                data-aos-duration="400"
                v-if="affiliationMicrositeData.youtubeVideo && affiliationMicrositeData.youtubeVideo != ''"
              >
                <iframe
                  class="rounded-xl"
                  width="560"
                  height="315"
                  :src="youtubeCut(affiliationMicrositeData.youtubeVideo)"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="4">
            <v-row>
              <v-col cols="12">
                <v-card
                  class="px-3 pt-6 pb-2"
                  rounded="xl"
                  elevation="0"
                  v-bind:style="`border: 1px solid ${primaryColor}`"
                >
                  <v-row class="px-2">
                    <v-col cols="12" class="pb-0">
                      <p
                        class="headline font-weight-bold"
                        v-bind:style="`color: ${primaryColor}`"
                      >
                        Organizador
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="px-2">
                    <v-col v-if="affiliationMicrositeData.organization && affiliationMicrositeData.organization.profilePicture && affiliationMicrositeData.organization.profilePicture!= ''" cols="12" class="d-flex justify-center py-0">
                      <v-img class="rounded-xl" max-width="130" height="auto" :src="affiliationMicrositeData.organization.profilePicture"></v-img>
                    </v-col>
                    <v-col v-if="affiliationMicrositeData.organization" cols="12">
                      <p class="mb-0 font-weight-bold subtitle-1 text-center">
                        {{affiliationMicrositeData.organization.company}}
                      </p>
                      <p class="text-center mb-0">{{affiliationMicrositeData.emailContact}}</p>
                    </v-col>
                    <v-col v-if="this.affiliationMicrositeData.socialMedia" cols="12" class="mb-4">
                      <v-row class="d-flex justify-center">
                        <v-btn
                          v-if="this.affiliationMicrositeData.socialMedia.facebook!=''"
                          icon
                          :color="buttonColor"
                          :href="this.affiliationMicrositeData.socialMedia.facebook"
                          target="_blank"
                        >
                          <v-icon>mdi-facebook</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.affiliationMicrositeData.socialMedia.instagram!=''"
                          icon
                          :color="buttonColor"
                          :href="this.affiliationMicrositeData.socialMedia.instagram"
                          target="_blank"
                        >
                          <v-icon>mdi-instagram</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.affiliationMicrositeData.socialMedia.twitter!=''"
                          icon
                          :color="buttonColor"
                          :href="this.affiliationMicrositeData.socialMedia.twitter"
                          target="_blank"
                        >
                          <v-icon>mdi-twitter</v-icon>
                        </v-btn>
                        <v-btn
                          v-if="this.affiliationMicrositeData.socialMedia.linkedin!=''"
                          icon
                          :color="buttonColor"
                          :href="this.affiliationMicrositeData.socialMedia.linkedin"
                          target="_blank"
                        >
                          <v-icon>mdi-linkedin</v-icon>
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
              <v-col v-if="this.affiliationMicrositeData.sponsors && this.affiliationMicrositeData.sponsors.length > 0" cols="12">
                <v-card
                  class="px-3 pt-6 pb-2"
                  rounded="xl"
                  elevation="0"
                  v-bind:style="`border: 1px solid ${primaryColor}`"
                >
                  <v-row class="px-2">
                    <v-col cols="12" class="pb-0">
                      <p
                        class="headline font-weight-bold"
                        v-bind:style="`color: ${primaryColor}`"
                      >
                        Patrocinadores
                      </p>
                    </v-col>
                    <v-col cols="12" class="d-flex justify-center">
                      <v-row justify="center">
                        <v-col cols="6" md="4" xl="3" class="d-flex justify-center pa-5" v-for="sponsor in this.affiliationMicrositeData.sponsors" :key="sponsor._id">
                          <v-img class="rounded-lg align-self-center sponsor-img" :src="sponsor.logo" max-width="70" height="auto"></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="this.affiliationMicrositeData.faqs && this.affiliationMicrositeData.faqs.length > 0">
      <v-col cols="12" sm="10" offset-sm="1" class="px-6 px-md-2 pb-0 pt-5 d-flex align-center">
        <p
          class="headline font-weight-bold mb-1 mt-4"
          v-bind:style="`color: ${primaryColor}`"
        >
          Preguntas frecuentes
        </p>
        <v-tooltip top max-width="350px">
          <template v-slot:activator="{ on }">
            <v-icon
              :color="buttonColor"
              dark
              v-on="on"
              class="ml-2 mt-4"
              >mdi-help-circle</v-icon
            >
          </template>
          <span
            >Si estás interesado en esta membresía, en este apartado se responden las preguntas frecuentes sobre la membresía que ofrece la organización.</span
          >
        </v-tooltip>
      </v-col>
      <v-col cols="12" sm="10" offset-sm="1" class="px-6 px-md-2">
        <v-expansion-panels multiple flat>
          <v-expansion-panel v-for="item in this.affiliationMicrositeData.faqs" :key="item.id" class="rounded-xl my-2" style="background:#F0F0F0">
            <v-expansion-panel-header>
              <span class="subtitle-1 font-weight-bold">{{item.question}}</span>
              <template v-slot:actions>
                <v-icon :color="buttonColor">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.answer}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <v-row class="my-10">
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
      <v-col cols="12" class="d-flex justify-center">
        <v-img max-width="150" height="auto" src="@/assets/images/weChamber/poweredby.svg"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {Base64} from 'js-base64';
export default {
  computed:{
    ...mapState("affiliation", ["affiliationSelected", "affiliationMicrositeData"]),
    ...mapState("loader", ["loaderVisible"]),
  },
  data() {
    return {
      affiliationId: '',
      socialMediaIcons: {
        facebook: "mdi-facebook",
        twitter: "mdi-twitter",
        linkedin: "mdi-linkedin",
        instagram: "mdi-instagram"
      },
      primaryColor: "#FE484B",
      secondaryColor: "",
      buttonColor: "",
      textButtonColor: "",
    };
  },
  methods:{
    ...mapActions("affiliation", ["fetchAffiliationMicrositeData", "fetchAffiliationById"]),
    ...mapMutations("loader", ["loading", "loaded"]),
    youtubeCut(youtube){
      let youtubeId = "";

      if (youtube.startsWith("https://youtu.be/")) {
        youtubeId = youtube.split("https://youtu.be/")[1];
      } else {
        if (youtube.includes("?v=")) {
          youtubeId = youtube.split("?v=")[1];
        } else if (youtube.includes("&v=")) {
          youtubeId = youtube.split("&v=")[1];
        }
      }
      youtubeId = youtubeId.split("&")[0];
      return `https://www.youtube.com/embed/${youtubeId}`;
    },
  },
  async mounted(){
    this.loading();
    

    await setTimeout(async () => {
    this.affiliationId = this.$route.params.affiliationId;
    let response= await this.fetchAffiliationMicrositeData(this.affiliationId);
    console.log(response);
    console.log(this.affiliationMicrositeData);
    this.primaryColor = this.affiliationMicrositeData.micrositeConfiguration.primaryColor;
    this.secondaryColor = this.affiliationMicrositeData.micrositeConfiguration.secondaryColor;
    this.buttonColor = this.affiliationMicrositeData.micrositeConfiguration.buttonColor;
    this.textButtonColor = this.affiliationMicrositeData.micrositeConfiguration.textButtonColor;
    console.log("Datos de la membresía");
    console.log(response);
    console.log(this.affiliationMicrositeData);

    if(this.affiliationMicrositeData.description != ''){
      this.affiliationMicrositeData.description = Base64.decode(this.affiliationMicrositeData.description);
    }

    if(this.affiliationMicrositeData.presentialAffiliationConfiguration && this.affiliationMicrositeData.presentialAffiliationConfiguration.latitude){
      this.googleMapsPosition.lat = this.affiliationMicrositeData.presentialAffiliationConfiguration.latitude;
      this.googleMapsPosition.lng = this.affiliationMicrositeData.presentialAffiliationConfiguration.longitude;
    }
      this.loaded();
    }, 2000);
    
    
  }
};
</script>
<style scoped>
.sponsor-img {
  filter: grayscale(1);
  height: auto;
  transition: 0.2s all ease-in-out;
  border-radius: 15px;
  justify-self: center;
}

.grayscale-disabled-element {
  filter: grayscale(1);
}

.loader-container {
  height: 95vh;
}
.banner-img {
  width: 100%;
  height: auto;
}
.main-information-container {
  background-color: #fe484b;
}
affiliation-custom-content >>> h1,
h2,
h3,
h4 {
  font-weight: 700 !important;
}
.affiliation-custom-content >>> blockquote {
  border-left: 4px solid #ccc !important;
  margin-bottom: 5px !important;
  margin-top: 5px !important;
  padding-left: 16px !important;
}
@media (min-width: 400px) and (max-width: 599px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1.05em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 1em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 0.95em !important;
  }
}
@media (min-width: 600px) and (max-width: 959px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 0.95em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 0.9em !important;
  }
}
@media (min-width: 960px) and (max-width: 1263px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1.2em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 1em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 0.9em !important;
  }
}
@media (min-width: 1264px) and (max-width: 1599px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1.28em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 1em !important;
  }
}
@media (min-width: 1599px) and (max-width: 1903px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1.5em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 1.1em !important;
  }
}
@media (min-width: 1904px) {
  .affiliation-custom-content >>> h1 {
    font-size: 1.5em !important;
  }
  .affiliation-custom-content >>> h2 {
    font-size: 1.2em !important;
  }
  .affiliation-custom-content >>> h3 {
    font-size: 1.1em !important;
  }
}
</style>